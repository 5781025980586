.content-item-two {
  margin-right: 0.625vw;
  font-size: 0px;

  img {
    width: 22.5vw;
    height: 12.5521vw;
    border-radius: 0.4167vw 0.4167vw 0 0;
    font-size: 0px;
  }
  .image-placeholder {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 22.5vw;
    height: 12.5521vw;
    border-radius: 0.4167vw 0.4167vw 0 0;
  }

  .text {
    font-size: 0px;
    height: 5.625vw;
    box-sizing: border-box;
    padding-left: 1.0417vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(37, 37, 43, 1);
    border-radius: 0px 0px 0.4167vw 0.4167vw;
    .title_,
    .msg_ {
      width: 21.4583vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .title_ {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 0.5208vw;
    }
    .msg_ {
      font-size: 14px;
      color: #999999;
    }
  }
}
@media screen and (min-width: 1920px) {
  .content-item-two {
    margin-right: 12px;
  }
}
.three {
  .content {
    min-height: 280px;
  }
  .content.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
