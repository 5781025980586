.take-turns {
  width: 100%;
  height: inherit;
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .function {
    position: absolute;
    left: 4.1146vw;
    bottom: 5.1563vw;
    z-index: 999;
    display: flex;
    .btn {
      width: 2.5vw;
      cursor: pointer;
      height: 2.5vw;
      background-size: contain;
    }
    .btn:first-child {
      margin-right: 1.0417vw;
      background-image: url('../../../../assets/images/bf.png');
    }
    .btn:last-child {
      background-image: url('../../../../assets/images/sc.png');
    }
  }
  .loading_line-jds2lk {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}
