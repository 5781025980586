.top_box {
  width: 100%;
  height: auto;
  position: absolute;
  // z-index: 999;
  z-index: 899;
  user-select: none;
}

.top_box_bto {
  width: 100%;
  height: auto;
  min-height: 72px;
  background-color: rgba(30, 30, 36, 1);
}

.top {
  width: 100%;
  height: auto;
  min-height: 72px;
  box-sizing: border-box;
  padding: 16px 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1900px;
  margin: 0 auto;
  justify-content: space-between;

  & > img {
    width: auto;
    height: 36px;
    cursor: pointer;
  }

  .search {
    flex: 1;
    max-width: 500px;
    min-width: 150px;
    height: 36px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 18px;
    background: rgba(256, 256, 256, 0.2);
    overflow: hidden;
    padding: 5px 5px 5px 15px;
    box-sizing: border-box;
    margin: 0 20px;

    input {
      flex: 1;
      width: 100%;
      height: 100%;
      border: none;
      font-size: 16px;
      border-radius: 20px;
      background: transparent;
      outline: none;
      box-sizing: border-box;
      color: hsla(0, 0%, 100%, 0.86);
    }

    .btn {
      width: 28px;
      height: 28px;
      cursor: pointer;
      margin-left: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .function_fdsf232 {
    display: flex;

    li.btn {
      min-height: 42px;
      min-width: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: rgba(255, 211, 139, 1);
      margin: 0 6px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;

      img {
        width: 24px;
        height: auto;
      }

      &:hover {
        color: rgb(246, 180, 73);
      }

      &:last-of-type {
        & > img {
          border-radius: 50%;
        }

        &:hover {
          color: red;
        }
      }
    }

    li.user {
      width: 40px;
      height: 40px;

      img {
        width: inherit;
        height: inherit;
      }
    }
  }
}

.top.home {
  position: absolute;
  top: 19px;
  left: 0;
  z-index: 999;
  background-color: transparent;
}
